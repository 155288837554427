{
  "form": {
    "required": "{name} is required",
    "incomplete": "Sorry, not all fields are filled out correctly.",
    "invalidPhoneNumber": "Invalid phone number"
  },
  "orderFlow": {
    "error": {
      "description": "Something happened. Your order was incomplete. Please try again."
    },
    "confirmPayment": {
      "description": "You will be redirected to the payment gateway in a moment.",
      "redirectToGateway": "Redirect to payment gateway"
    },
    "complete": {
      "description": "Your order has been successfully completed.",
      "receiptIsReadyToDownload": "Your receipt is ready and you can download it to your device."
    },
    "showPaymentFailure": {
      "description": "Your payment was not completed successfully."
    },
    "waitForPayment": {
      "description": "We are waiting for the result of your payment."
    },
    "waitForReceipt": {
      "description": "We generate a receipt. Wait a minute."
    },
    "timeout": {
      "description": "The time limit has been exceeded. We will send you a notification of the result."
    }
  },
  "cancel": "Cancel",
  "no": "No",
  "yes": "Yes",
  "order": "Order",
  "emailAddress": "E-mail address",
  "phoneNumber": "Phone number",
  "somethingHappenedTryItAgain": "Something happened. Please try again.",
  "goBack": "Go back",
  "download": "Download",
  "firstName": "First name",
  "lastName": "Last name",
  "companyName": "Company name",
  "regNum": "Registration No.",
  "vat": "TAX ID",
  "street": "Street",
  "houseNum": "House Number",
  "city": "City",
  "zip": "Zip Code",
  "country": "Země",
  "accept": "Accept",
  "refuse": "Refuse",
  "localeSettings": {
    "title": "Language Settings",
    "localeSelect": "Use language"
  },
  "baasSimManagementLite": {
    "simAuthenticationFailed": "SIM Authentication failed",
    "simLoading": "SIM loading...",
    "noActivePackage": "No package",
    "availablePackages": "Available packages",
    "simLoadDataFailed": "Loading SIM data failed",
    "inactiveAndSynchronizationInProgress": "The SIM is not active. It can take up to 2 hours to synchronise the status.",
    "login": {
      "authorizationCode": "Authorization Code",
      "authorizationCodeValidation": "Authorization code is max 6 length alphanum code."
    },
    "loginType": {
      "go": "Go",
      "identificationCode": "Identification code",
      "insertSimIdentification": "Please insert SIM identification",
      "invalidIdentificationCode": "Invalid identification code"
    },
    "package": {
      "purchaseInProgress": "Purchase in progress",
      "buyFor": "Buy for",
      "inactiveAndSynchronizationInProgress": "The SIM is not active. It can take up to 2 hours to synchronise the status."
    },
    "packageOrder": {
      "description": "Before we redirect you to the payment gateway, please enter your email address to which we will send you proof of payment.",
      "requiredTermsAndConditions": "Before ordering a package you need to agree to the terms and conditions",
      "showBillingInfo": "Fill in billing information",
      "billingName": "Name"
    },
    "unprocessedPackages": {
      "foundUnprocessed": "The order is being processed. This operation may take a few minutes. Please wait.",
      "warnUnprocesse:": "The order is being processed. Are you sure you want to order another package?"
    },
    "usageBar": {
      "total": "Total",
      "used": "Used",
      "remaining": "Remaining",
      "validTo": "Valid to"
    }
  },
  "proposal": {
    "acceptForm": {
      "availableOffers": "Available offers",
      "companyInformations": "Company informations",
      "address": "Address",
      "contactPersonInformation": "Contact person information",
      "proposalAccpetFailed": "Acceptation of the proposal failed",
      "alternative": "Alternative"
    },
    "refuseDialog": {
      "refuseDialogTitle": "Confirmation of refuse of the offer"
    },
    "refuseForm": {
      "reasonText": "Please state the reason for rejecting the offer",
      "refuseDialogText": "Are you sure you want to refuse proposal?",
      "proposalRefuseFailed": "Refuse of the proposal failed"
    },
    "proposalLoading": "Proposal loading...",
    "proposalLoadDataFailed": "Loading proposal data failed",
    "proposalIsAccepted": "The proposal is accepted",
    "proposalIsRefused": "The proposal is refused",
    "proposalNumber": "Offer no.",
    "validTo": "Valid to"
  },
  "languages": {
    "en": "English",
    "cs": "Čeština",
    "sk": "Slovenský",
    "pl": "Polski",
    "de": "Deutsch"
  }
}
