{
  "form": {
    "required": "{name} jest wymagane",
    "incomplete": "Przepraszamy, nie wszystkie pola są wypełnione poprawnie.",
    "invalidPhoneNumber": "Nieważny numer telefonu",
    "phonePlaceholder": "Wyszukaj kody połączeń do kraju"
  },
  "orderFlow": {
    "error": {
      "description": "Coś się stało. Twoje zamówienie było niekompletne. Proszę spróbować ponownie."
    },
    "confirmPayment": {
      "description": "Za chwilę zostaniesz przekierowany do bramki płatności.",
      "redirectToGateway": "Przekierowanie do bramki płatności"
    },
    "complete": {
      "description": "Twoje zamówienie zostało pomyślnie zrealizowane.",
      "receiptIsReadyToDownload": "Twój paragon jest gotowy i możesz go pobrać na swoje urządzenie."
    },
    "showPaymentFailure": {
      "description": "Twoja płatność nie została zakończona pomyślnie."
    },
    "waitForPayment": {
      "description": "Czekamy na wynik Twojej płatności."
    },
    "waitForReceipt": {
      "description": "Generujemy paragon. Poczekajcie chwilę."
    },
    "timeout": {
      "description": "Limit czasu został przekroczony. Wyślemy do Ciebie powiadomienie o wyniku."
    }
  },
  "cancel": "Anuluj",
  "no": "Nie",
  "yes": "Tak",
  "order": "Zamówienie",
  "emailAddress": "Adres e-mail",
  "phoneNumber": "Numer telefonu",
  "somethingHappenedTryItAgain": "Coś się stało. Proszę spróbować ponownie.",
  "goBack": "Wróć",
  "download": "Pobierz",
  "noMatches": "Brak dopasowań",
  "firstName": "Imię",
  "lastName": "Nazwisko",
  "companyName": "Company name",
  "regNum": "REGON",
  "vat": "NIP",
  "street": "Ulica",
  "houseNum": "Numer domu",
  "city": "Miasto",
  "zip": "ZIP",
  "country": "Kraj",
  "accept": "Akceptuj",
  "refuse": "Odrzucić",
  "localeSettings": {
    "title": "Ustawienia języka",
    "localeSelect": "Używaj języka"
  },
  "baasSimManagementLite": {
    "simAuthenticationFailed": "Uwierzytelnianie SIM nie powiodło się",
    "simLoading": "Ładowanie karty SIM...",
    "noActivePackage": "Bez pakietu",
    "availablePackages": "Dostępne pakiety",
    "simLoadDataFailed": "Nie udało się załadować danych SIM",
    "inactiveAndSynchronizationInProgress": "Karta SIM nie jest aktywna. Synchronizacja stanu może potrwać do 2 godzin.",
    "login": {
      "authorizationCode": "Authorization Code",
      "authorizationCodeValidation": "Authorization code is max 6 length alphanum code."
    },
    "loginType": {
      "go": "Go",
      "identificationCode": "Kod identyfikacyjny",
      "insertSimIdentification": "Włóż identyfikator karty SIM",
      "invalidIdentificationCode": "Nieprawidłowy kod identyfikacyjny"
    },
    "package": {
      "purchaseInProgress": "Zakup na bieżąco",
      "buyFor": "Kupić za",
      "inactiveAndSynchronizationInProgress": "Karta SIM nie jest aktywna. Synchronizacja stanu może potrwać do 2 godzin."
    },
    "packageOrder": {
      "description": "Prosimy o podanie adresu e-mail przed przekierowaniem do bramki płatności, na który wyślemy dowód wpłaty.",
      "requiredTermsAndConditions": "Przed zamówieniem pakietu należy zaakceptować regulamin.",
      "showBillingInfo": "Wypełnij informacje rozliczeniowe",
      "billingName": "Nazwisko"
    },
    "unprocessedPackages": {
      "foundUnprocessed": "Zamówienie jest przetwarzane. Ta operacja może potrwać kilka minut. Prosimy o oczekiwanie.",
      "warnUnprocesse": "Zamówienie jest przetwarzane. Czy na pewno chcesz zamówić kolejny pakiet?"
    },
    "usageBar": {
      "total": "Razem",
      "used": "Zużyte",
      "remaining": "Pozostaje",
      "validTo": "Ważność do"
    }
  },
  "proposal": {
    "acceptForm": {
      "availableOffers": "Dostępne oferty",
      "companyInformations": "Informacje o firmie",
      "address": "Adres",
      "contactPersonInformation": "Dane osoby kontaktowej",
      "proposalAccpetFailed": "Przyjęcie propozycji nie powiodło się",
      "alternative": "Alternatywa"
    },
    "refuseDialog": {
      "refuseDialogTitle": "Potwierdzenie odrzucenia oferty"
    },
    "refuseForm": {
      "reasonText": "Prosimy o podanie powodu odrzucenia oferty",
      "refuseDialogText": "Czy na pewno chcesz odrzucić propozycję?",
      "proposalRefuseFailed": "Odrzucenie propozycji nie powiodło się"
    },
    "proposalLoading": "Ładowanie propozycji...",
    "proposalLoadDataFailed": "Ładowanie danych propozycji nie powiodło się",
    "proposalIsAccepted": "Propozycja zostaje przyjęta",
    "proposalIsRefused": "Propozycja zostaje odrzucona",
    "proposalNumber": "Ponuka č.",
    "validTo": "Ważne do"
  },
  "languages": {
    "en": "English",
    "cs": "Čeština",
    "sk": "Slovenský",
    "pl": "Polski",
    "de": "Deutsch"
  }
}
