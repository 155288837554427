{
  "form": {
    "required": "{name} je povinný údaj",
    "incomplete": "Omlouváme se, ale ne všechna pole jsou vyplněna správně.",
    "invalidPhoneNumber": "Neplatné telefonní číslo",
    "phonePlaceholder": "Najít mezinárodní předvolbu"
  },
  "orderFlow": {
    "error": {
      "description": "Něco se stalo. Vaše objednávka nebyla nedokončena. Prosím, zkuste to znovu."
    },
    "confirmPayment": {
      "description": "Během chvíle budete přesměrovány na platební bránu.",
      "redirectToGateway": "Přesměrovat na platební bránu"
    },
    "complete": {
      "description": "Vaše objednávka byla úspěšně dokončena.",
      "receiptIsReadyToDownload": "Vaše doklad je připraven a můžete si ho stáhnout do svého zařízení."
    },
    "showPaymentFailure": {
      "description": "Vaše platba nebyla úspěšně dokončena."
    },
    "waitForPayment": {
      "description": "Čekáme na výsledek Vaší platby."
    },
    "waitForReceipt": {
      "description": "Generujeme účtenku. Vydržte chvíli."
    },
    "timeout": {
      "description": "Byl překročen časový limit. Zašleteme Vám notifikaci o výsledku."
    }
  },
  "cancel": "Zrušit",
  "no": "Ne",
  "yes": "Ano",
  "order": "Objednat",
  "emailAddress": "E-mailová adresa",
  "phoneNumber": "Telefonní číslo",
  "somethingHappenedTryItAgain": "Něco se stalo. Prosím, zkuste to znovu.",
  "goBack": "Vrátit se zpět",
  "download": "Stáhnout",
  "noMatches": "Žádná shoda",
  "firstName": "Křestní jméno",
  "lastName": "Příjmení",
  "companyName": "Název firmy",
  "regNum": "IČO",
  "vat": "DIČ",
  "street": "Ulice",
  "houseNum": "Číslo domu",
  "city": "Město",
  "zip": "ZIP",
  "country": "Země",
  "accept": "Příjmout",
  "refuse": "Odmítnout",
  "localeSettings": {
    "title": "Jazykové nastavení",
    "localeSelect": "Jazyk"
  },
  "baasSimManagementLite": {
    "simAuthenticationFailed": "Selhalo přihlášení na SIM",
    "simLoading": "Načitání SIM...",
    "noActivePackage": "Žádný balíček",
    "availablePackages": "Dostupné balíčky",
    "simLoadDataFailed": "Selhalo načtení dat SIM",
    "inactiveAndSynchronizationInProgress": "SIM není aktivní. Sychronizace stavu může trvat až 2 hodiny.",
    "login": {
      "authorizationCode": "Authorization kód",
      "authorizationCodeValidation": "Autorizační kód je maximálně 6 dlouhý. Obsahující pouze písmena a čísla."
    },
    "loginType": {
      "go": "Go",
      "identificationCode": "Identifikační kód",
      "insertSimIdentification": "Prosím vložte SIM identifikaci",
      "invalidIdentificationCode": "Neplatný identifikační kód"
    },
    "package": {
      "purchaseInProgress": "Probíhá nákup",
      "buyFor": "Koupit za",
      "inactiveAndSynchronizationInProgress": "SIM není aktivní. Sychronizace stavu může trvat až 2 hodiny."
    },
    "packageOrder": {
      "description": "Před tím než vás přesměrujeme na platební bránu prosím zadejte váš e-mail, na který vám zašleme doklad o zaplacení.",
      "requiredTermsAndConditions": "Před objednání balíčku je potřeba souhlasit s obchodními podmínkami",
      "showBillingInfo": "Vyplňte fakturační údaje",
      "billingName": "Jméno"
    },
    "unprocessedPackages": {
      "foundUnprocessed": "Probíhá zpracování objednávky. Tato operace může trvat pár minut. Prosím vyčkejte.",
      "warnUnprocesse": "Probíhá zpracování objednávky. Opravdu chcete objednat další balíček?"
    },
    "usageBar": {
      "total": "Celkem",
      "used": "Spotřebováno",
      "remaining": "Zbývá",
      "validTo": "Platnost do"
    }
  },
  "proposal": {
    "acceptForm": {
      "availableOffers": "Dostupné nabídky",
      "companyInformations": "Informace o společnosti",
      "address": "Adresa",
      "contactPersonInformation": "Informace o kontaktní osobě",
      "proposalAccpetFailed": "Přijetí nabídky se nezdařilo",
      "alternative": "Alternativa"
    },
    "refuseDialog": {
      "refuseDialogTitle": "Potvrzení odmítnutí nabídky"
    },
    "refuseForm": {
      "reasonText": "Uveďte prosím důvod odmítnutí nabídky",
      "refuseDialogText": "Jste si jisti, že chcete nabídku odmítnout?",
      "proposalRefuseFailed": "Zamítnutí návrhu se nepodařilo"
    },
    "proposalLoading": "Načitání nabídky...",
    "proposalLoadDataFailed": "Načtení nabídky selhalo",
    "proposalIsAccepted": "Nabídka je přijatá",
    "proposalIsRefused": "Nabídka je odmítnuta",
    "proposalNumber": "Nabídka č.",
    "validTo": "Platnost do"
  },
  "languages": {
    "en": "English",
    "cs": "Čeština",
    "sk": "Slovenský",
    "pl": "Polski",
    "de": "Deutsch"
  }
}
