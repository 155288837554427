<template>
  <NuxtPage />
</template>

<script setup>
import { useWidgetOptions } from '@/stores/widget'
import { routes } from '~~/utils/widget'

const { $api, vueApp } = useNuxtApp()
const router = useRouter()
const route = useRoute()
const widgetOptions = useWidgetOptions()
const widget = useWidget()
const i18n = useI18n()

onBeforeMount(() => {
  const hash = route.params.hash
  const path = route.params.path
  const query = route.query

  $api.public
    .getWidget(hash)
    .then(async response => {
      if (routes.hasOwnProperty(response.type.code)) {
        const widgetRoutes = routes[response.type.code]

        widgetOptions.setPartnerId(response.partner.id)

        if (response.images) {
          widgetOptions.setImages(response.images)
        }

        if (response.parameters) {
          widgetOptions.setParameters(response.parameters)
        }

        if (widgetOptions.hasImage("bodyBackground")) {
          const head = vueApp._context.provides.usehead
          head.push({
            style: [{ children: `body { background-image: url(${widgetOptions.getImage("bodyBackground")}) }` }]
          })
        }

        if (response.style?.css) {
          // Workaround for not working useHead function. May check later for fix.
          const head = vueApp._context.provides.usehead
          head.push({
            style: [{ children: response.style.css }]
          })
        }

        if (response.localizations) {
          widgetOptions.setLocalizations(response.localizations)
        }

        if (!i18n.getLocaleCookie()) {
          if (response.defaultLocale) {
            i18n.setLocale(response.defaultLocale)
          } else if (i18n.getBrowserLocale()) {
            i18n.setLocale(i18n.getBrowserLocale())
          }
        }

        if (router.hasRoute('widget-path')) {
          router.removeRoute('widget-path')

          router.addRoute({
            name: 'widget-widget1',
            path: '/widget/:hash',
            component: () => import(`~/pages/widget/${response.type.code}.vue`),
            children: widgetRoutes
          })

          if (path) {
            await widget.redirectTo(path, { query: query })
            /*await navigateTo({
            path: `/widget/${hash}/${path}`,
            query: query
          })*/
          } else {
            await widget.redirectTo('home')
          }
        }
      } else {
        showError({ statusCode: 404, message: 'Unknown widget type' })
      }
    })
    .catch(e => {
      console.log(e)
      showError('Widget loading failed')
    })
})
</script>
