{
  "form": {
    "required": "{name} je vyžadováno",
    "incomplete": "Ospravedlňujeme sa, ale nie všetky polia sú vyplnené správne.",
    "invalidPhoneNumber": "Neplatné telefónne číslo",
    "phonePlaceholder": "Vyhľadanie predvoľby"
  },
  "orderFlow": {
    "error": {
      "description": "Niečo sa stalo. Vaša objednávka bola neúplná. Skúste to prosím znova."
    },
    "confirmPayment": {
      "description": "O chvíľu budete presmerovaní na platobnú bránu.",
      "redirectToGateway": "Presmerovanie na platobnú bránu"
    },
    "complete": {
      "description": "Vaša objednávka bola úspešne dokončená.",
      "receiptIsReadyToDownload": "Váš doklad je pripravený a môžete si ho stiahnuť do svojho zariadenia."
    },
    "showPaymentFailure": {
      "description": "Vaša platba nebola úspešne dokončená."
    },
    "waitForPayment": {
      "description": "Čakáme na výsledok vašej platby."
    },
    "waitForReceipt": {
      "description": "Vygenerujeme potvrdenie o prijatí. Počkajte chvíľu."
    },
    "timeout": {
      "description": "Časový limit bol prekročený. Pošleme vám oznámenie o výsledku."
    }
  },
  "cancel": "Zrušiť",
  "no": "Nie",
  "yes": "Áno",
  "order": "Objednávka",
  "emailAddress": "E-mailová adresa",
  "phoneNumber": "Telefónne číslo",
  "somethingHappenedTryItAgain": "Niečo sa stalo. Skúste to prosím znova.",
  "goBack": "Vráťte sa späť",
  "download": "Stiahnite si",
  "noMatches": "Žiadna zhoda",
  "firstName": "Krstné meno",
  "lastName": "Priezvisko",
  "companyName": "Názov spoločnosti",
  "regNum": "IČO",
  "vat": "DIČ",
  "street": "Ulica",
  "houseNum": "Číslo domu",
  "city": "Mesto",
  "zip": "ZIP",
  "country": "Krajina",
  "accept": "Prijať",
  "refuse": "Odmietnuť",
  "localeSettings": {
    "title": "Nastavenia jazyka",
    "localeSelect": "Jazyk"
  },
  "baasSimManagementLite": {
    "simAuthenticationFailed": "Overenie pravosti karty SIM zlyhalo",
    "simLoading": "Načítanie karty SIM...",
    "noActivePackage": "Žiadny balík",
    "availablePackages": "Dostupné balíky",
    "simLoadDataFailed": "Načítanie údajov SIM karty sa nepodarilo",
    "inactiveAndSynchronizationInProgress": "Karta SIM nie je aktívna. Synchronizácia stavu môže trvať až 2 hodiny.",
    "login": {
      "authorizationCode": "Authorization Code",
      "authorizationCodeValidation": "Authorization code is max 6 length alphanum code."
    },
    "loginType": {
      "go": "Go",
      "identificationCode": "Identifikačný kód",
      "insertSimIdentification": "Vložte identifikáciu SIM karty",
      "invalidIdentificationCode": "Neplatný identifikačný kód"
    },
    "package": {
      "purchaseInProgress": "Priebežný nákup",
      "buyFor": "Kúpiť za",
      "inactiveAndSynchronizationInProgress": "Karta SIM nie je aktívna. Synchronizácia stavu môže trvať až 2 hodiny."
    },
    "packageOrder": {
      "description": "Pred presmerovaním na platobnú bránu zadajte svoju e-mailovú adresu, na ktorú vám zašleme doklad o zaplatení.",
      "requiredTermsAndConditions": "Pred objednaním balíka musíte súhlasiť s podmienkami",
      "showBillingInfo": "Vyplňte fakturačné údaje",
      "billingName": "Meno"
    },
    "unprocessedPackages": {
      "foundUnprocessed": "Objednávka sa spracováva. Táto operácia môže trvať niekoľko minút. Počkajte, prosím.",
      "warnUnprocesse": "Objednávka sa spracováva. Určite si chcete objednať ďalší balíček?"
    },
    "usageBar": {
      "total": "Celkom",
      "used": "Spotrebované",
      "remaining": "Zostáva",
      "validTo": "Platnosť do"
    }
  },
  "proposal": {
    "acceptForm": {
      "availableOffers": "Dostupné ponuky",
      "companyInformations": "Informácie o spoločnosti",
      "address": "Adresa",
      "contactPersonInformation": "Informácie o kontaktnej osobe",
      "proposalAccpetFailed": "Prijatie ponuky nebolo úspešné",
      "alternative": "Alternatíva"
    },
    "refuseDialog": {
      "refuseDialogTitle": "Potvrdenie odmietnutia ponuky"
    },
    "refuseForm": {
      "reasonText": "Uveďte, prosím, dôvod odmietnutia ponuky",
      "refuseDialogText": "Ste si istí, že chcete návrh odmietnuť?",
      "proposalRefuseFailed": "Zamietnutie návrhu sa nepodarilo"
    },
    "proposalLoading": "Načítanie ponúk...",
    "proposalLoadDataFailed": "Načítanie ponuky sa nepodarilo",
    "proposalIsAccepted": "Ponuka je prijatá",
    "proposalIsRefused": "Ponuka je odmietnutá.",
    "proposalNumber": "Ponuka č.",
    "validTo": "Platnosť do"
  },
  "languages": {
    "en": "English",
    "cs": "Čeština",
    "sk": "Slovenský",
    "pl": "Polski",
    "de": "Deutsch"
  }
}
