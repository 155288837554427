{
  "form": {
    "required": "{name} ist erforderlich",
    "incomplete": "Leider sind nicht alle Felder korrekt ausgefüllt.",
    "invalidPhoneNumber": "Ungültige Rufnummer",
    "phonePlaceholder": "Ländervorwahlen suchen"
  },
  "orderFlow": {
    "error": {
      "description": "Es ist etwas passiert. Ihre Bestellung war unvollständig. Bitte versuchen Sie es erneut."
    },
    "confirmPayment": {
      "description": "Sie werden in Kürze zum Zahlungsportal weitergeleitet.",
      "redirectToGateway": "Weiterleitung zum Zahlungs-Gateway"
    },
    "complete": {
      "description": "Ihre Bestellung wurde erfolgreich abgeschlossen.",
      "receiptIsReadyToDownload": "Ihre Quittung ist fertig und Sie können sie auf Ihr Gerät herunterladen."
    },
    "showPaymentFailure": {
      "description": "Ihre Zahlung wurde nicht erfolgreich abgeschlossen."
    },
    "waitForPayment": {
      "description": "Wir warten auf das Ergebnis Ihrer Zahlung."
    },
    "waitForReceipt": {
      "description": "Wir erstellen eine Quittung. Warten Sie eine Minute."
    },
    "timeout": {
      "description": "Das Zeitlimit wurde überschritten. Wir werden Sie über das Ergebnis benachrichtigen."
    }
  },
  "cancel": "Abbrechen",
  "no": "Nein",
  "yes": "Ja",
  "order": "Bestellung",
  "emailAddress": "E-Mail Adresse",
  "phoneNumber": "Telefonnummer",
  "somethingHappenedTryItAgain": "Es ist etwas passiert. Bitte versuchen Sie es erneut.",
  "goBack": "Zurückgehen",
  "download": "Herunterladen",
  "noMatches": "Keine Treffer",
  "firstName": "Vorname",
  "lastName": "Nachname",
  "companyName": "Firmenname",
  "regNum": "Registrierung (HRA, o. äh.)",
  "vat": "USt-ID (DE xxxxxxxxx)",
  "street": "Straße",
  "houseNum": "Hausnummer",
  "city": "Ort",
  "zip": "PLZ",
  "country": "Land",
  "accept": "Akzeptieren",
  "refuse": "Ablehnen",
  "localeSettings": {
    "title": "Spracheinstellungen",
    "localeSelect": "Sprache verwenden"
  },
  "baasSimManagementLite": {
    "simAuthenticationFailed": "SIM-Authentifizierung fehlgeschlagen",
    "simLoading": "SIM-Laden...",
    "noActivePackage": "Kein Paket",
    "availablePackages": "Verfügbare Pakete",
    "simLoadDataFailed": "Laden der SIM-Daten fehlgeschlagen",
    "inactiveAndSynchronizationInProgress": "Die SIM-Karte ist nicht aktiv. Es kann bis zu 2 Stunden dauern, den Status zu synchronisieren.",
    "login": {
      "authorizationCode": "Authorization Code",
      "authorizationCodeValidation": "Authorization code is max 6 length alphanum code."
    },
    "loginType": {
      "go": "Go",
      "identificationCode": "SIM-ID",
      "insertSimIdentification": "Bitte SIM-ID eingeben",
      "invalidIdentificationCode": "Ungültige SIM-ID"
    },
    "package": {
      "purchaseInProgress": "Laufender Kauf",
      "buyFor": "Kaufen für",
      "inactiveAndSynchronizationInProgress": "Die SIM-Karte ist nicht aktiv. Es kann bis zu 2 Stunden dauern, den Status zu synchronisieren."
    },
    "packageOrder": {
      "description": "Bitte geben Sie Ihre E-Mail-Adresse ein, bevor Sie zum Zahlungsgateway weitergeleitet werden, an das wir den Zahlungsnachweis senden werden.",
      "requiredTermsAndConditions": "Bevor Sie ein Paket bestellen, müssen Sie den Allgemeinen Geschäftsbedingungen zustimmen",
      "showBillingInfo": "Rechnungsinformationen ausfüllen",
      "billingName": "Name"
    },
    "unprocessedPackages": {
      "foundUnprocessed": "Die Bestellung wird gerade bearbeitet. Dieser Vorgang kann ein paar Minuten dauern. Bitte warten Sie.",
      "warnUnprocesse": "Die Bestellung wird gerade bearbeitet. Sind Sie sicher, dass Sie ein weiteres Paket bestellen möchten?"
    },
    "usageBar": {
      "total": "Insgesamt",
      "used": "Verbraucht",
      "remaining": "Bleibt",
      "validTo": "Geltungsdauer bis"
    }
  },
  "proposal": {
    "acceptForm": {
      "availableOffers": "Verfügbare Angebote",
      "companyInformations": "Informationen zum Unternehmen",
      "address": "Adresse",
      "contactPersonInformation": "Informationen zur Kontaktperson",
      "proposalAccpetFailed": "Annahme des Angebots gescheitert",
      "alternative": "Alternativen"
    },
    "refuseDialog": {
      "refuseDialogTitle": "Bestätigung der Ablehnung des Angebots"
    },
    "refuseForm": {
      "reasonText": "Bitte geben Sie den Grund für die Ablehnung des Angebots an",
      "refuseDialogText": "Sind Sie sicher, dass Sie das Angebot ablehnen wollen?",
      "proposalRefuseFailed": "Die Ablehnung des Angebots ist gescheitert"
    },
    "proposalLoading": "Angebot laden...",
    "proposalLoadDataFailed": "Laden der Angebotsdaten fehlgeschlagen",
    "proposalIsAccepted": "Der Angebot wird akzeptiert",
    "proposalIsRefused": "Der Angebot wird abgelehnt",
    "proposalNumber": "Angebot-Nr.",
    "validTo": "Gultig bis"
  },
  "languages": {
    "en": "English",
    "cs": "Čeština",
    "sk": "Slovenský",
    "pl": "Polski",
    "de": "Deutsch"
  }
}
